<template>
  <div class="agrement_acontent">
    <p class="title">书营AI写作服务协议</p>
    <div class="container_precondition">
      <p class="main_title">前言</p>
      <p class="main">
        欢迎使用<b class="company"
          >浙江艺阁文化传媒集团有限公司（下称“艺阁集团”）</b
        >为您提供的书营AI智能创作平台。请您务必审慎阅读、充分理解以下内容。
      </p>
      <p class="main">
        <b
          >如您未满18周岁，或存在其他不具备与用户行为相适应的民事行为能力的情形，请您在法定监护人（“监护人”）的陪同、指导下阅读本协议，并在确保监护人同意本协议内容后使用书营AI智能创作平台。</b
        >您及您的监护人应依照法律法规规定，承担同意本协议及使用书营AI智能创作平台而导致的相应后果。
      </p>
      <p class="main">
        除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用书营AI智能创作平台。<b>如您不同意本协议或其中任何条款，您应立即停止使用书营AI智能创作平台。您点击“同意”，或者您使用书营AI智能创作平台，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意本协议。</b>本协议即在您与艺阁集团之间产生法律效力，成为对双方均具有约束力的法律文件。
      </p>
      <p class="main">
        书营AI智能创作平台再次提示您审慎阅读、充分理解各条款内容，<b>特别是限制或免除责任的相应条款，</b>限制或免除责任条款将以加粗或其他醒目形式提示您注意。
      </p>
    </div>
    <div class="container_main">
      <p class="main_title">一、 签约主体以及协议范围</p>
      <p class="main">（一）、定义及协议范围</p>
      <p class="main">1.1定义</p>
      <p class="main">
        <b>书营AI智能创作平台：</b
        >指域名为www.showwin.net的网站、“书营AI智能创作”客户端软件、及依托于前述网站或软件的关联网站或软件。
      </p>
      <p class="main">
        <b>书营AI公司或我们：</b>指书营AI智能创作平台的运营方即<b
          class="company"
          >艺阁集团</b
        >及其关联公司。
      </p>
      <p class="main">
        <b>书营AI账号或账号：</b
        >指用户在使用书营AI智能创作平台服务时可能需要注册的账号。用户可在书营AI智能创作平台注册并获得的账号，作为登录并使用书营AI智能创作平台的凭证。
      </p>
      <p class="main">
        <b>用户：</b>指书营AI智能创作平台的使用人，在本协议中更多地称为“您”。
      </p>
      <p class="main">1.2. 协议范围</p>
      <p class="main">
        如您使用或购买书营AI智能创作平台使用某服务，您可仍需确认具体服务对应的服务条款；请您审慎阅读、充分理解各条款内容，选择接受或不接受该服务条款。
      </p>
      <p class="main_title">二、账户的注册、使用与安全</p>
      <p class="main">2.1.账号获得</p>
      <p class="main">
        书营AI智能创作平台为您提供注册及登录通道，您可按照页面提示填写信息、阅读并同意本协议，在完成全部注册登录程序后，成为书营AI智能创作平台用户。<b>您还需要根据法律法规的规定（如有）填写您真实的身份信息，否则您可能无法使用书营AI智能创作平台服务或在使用书营AI智能创作平台服务过程中受到限制。</b>您了解并同意，您有义务保持您提供信息的真实性、有效性及完整性。
      </p>
      <p class="main">
        <b
          >您不得恶意注册书营AI智能创作平台账号，包括但不限于频繁注册、批量注册、使用他人身份注册或其他不以正常使用书营AI智能创作平台服务为目的的账号注册行为。</b
        >
      </p>
      <p class="main">2.2.账号信息设置</p>
      <p class="main">
        您在注册或使用书营AI智能创作平台时设置的名称、头像和简介等账号信息资料应遵守法律法规、社会道德风尚和信息真实性等原则，不得出现不良信息，不得冒用他人姓名、名称、字号、头像等或采取其他足以引起混淆的方式设置账号，<b
          >不得侵害第三方的合法权益。</b
        >
      </p>
      <p class="main">2.3.账号使用及权属</p>
      <p class="main">
        您注册获得的账号、设置的密码是您登录并以用户身份使用书营AI智能创作平台的凭证，你应当谨慎使用、妥善保管。您须对账号进行的所有活动和行为负责。若因您保管不善导致的盗号、密码丢失、账号被非法使用等责任由您自行承担。
      </p>
      <p class="main">
        一个书营AI账户仅能对应唯一的法律主体。除非有法律明文规定、司法裁定或者经书营AI智能创作平台同意的情况下，<b
          >您不得以任何方式转让、赠与或让他人继承您的书营AI账户。</b
        >
      </p>
      <p class="main">
        如发现他人未经授权使用您的账户和密码，您应立即通知书营AI智能创作平台；书营AI智能创作平台将协助您冻结账户、更改密码或进行其他安全设置；您理解书营AI智能创作平台对您的请求采取行动需要合理时间，<b
          >书营AI智能创作平台对在采取行动前已经产生的以及由您引发的后果（包括但不限于您的任何损失）不承担任何责任。</b
        >
      </p>
      <p class="main">
        书营AI智能创作平台亦会在网站服务端采取合理的技术措施保障账户的安全。
      </p>
      <p class="main">2.4. 账号注销及回收</p>
      <p class="main">
        您可以依照书营AI智能创作平台的流程申请注销您的书营AI账号，<b>但您仍应对您在注销账号前或使用书营AI智能创作平台服务期间的行为承担相应责任。</b>注销成功后，除非法律法规另有规定，<b
          class="company"
          >艺阁集团</b
        >不会为您提供账号记录、内容、虚拟财产等恢复或提供服务，请您谨慎操作。
      </p>
      <p class="main">
        您理解并同意，为了充分使用账号资源，<b>如您在注册后未及时进行初次登录使用或连续超过六个月未登录账号并使用，且不存在未到期或未履行完毕的持续性书营AI智能创作平台服务的，书营公司有权收回您的账号。</b>如您的账号被收回，您无法通过您此前持有的账号登录并使用书营AI智能创作平台，您账号下保存的个性化设置和使用记录也将无法恢复。
      </p>
      <p class="main_title">三、授权使用及限制</p>
      <p class="main">3.1.平台内容</p>
      <p class="main">
        平台及其平台所包含和提供的内容（包括但不限于软件、技术、
        程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档
        等）（“ 平台内容”）的知识产权属于书营AI智能创作平台所有。
      </p>
      <p class="main">3.2标识</p>
      <p class="main">
        书营AI智能创作平台的其他标识未经书营AI智能创作平台书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。
      </p>
      <p class="main">3.3.第三方服务</p>
      <p class="main">
        书营AI智能创作平台内容可能包含指向其他网站或资源的超链接。<b>书营AI智能创作平台对通过这些链接而访问到的任何第三方资料或资源不承担任何责任。您与第三方发生的任何交易均与书营AI智能创作平台无关。</b>请查阅任何链接网站的条款和条件以及隐私政策，它们可能与我们的相关规定不同。
      </p>
      <p class="main">3.4. 账户的冻结</p>
      <p class="main">
        <b
          >您的书营AI账户（全部或部分权限或功能）在如下情况可能被冻结（如书营AI帐户被限制资金转出功能等），书营AI智能创作平台将通过邮件、站内信、短信或电话等方式通知您：</b
        >
      </p>
      <p class="main">
        （1）基于智能创作平台或服务运行和交易安全的需要，如您发生或可能发生破坏或试图破坏书营AI或书营AI关联公司公平交易环境或正常交易秩序的，或任何使用含有书营AI或书营AI关联公司名称、品牌且对他人有误导嫌疑或任何使用某种中英文(全称或简称)、数字、域名等意图表示或映射与书营AI或其关联公司具有某种关联的；
      </p>
      <p class="main">
        （2）违反本服务条款、书营AI智能创作平台的相关规则、规范（如交易规则、管理规范）、服务说明以及其他服务协议/条款的；
      </p>
      <p class="main">（3）违反国家法律、法规、政策、法律文书的规定的；</p>
      <p class="main">
        （4）您遭到他人投诉，
        且对方已经提供了相关证据的，而您未按照我们的要求提供相反证据的；
      </p>
      <p class="main">
        （5）书营AI智能创作平台根据合理分析判断您的账户操作、收益、兑换等存在异常的；
      </p>
      <p class="main">（6）国家有权机关要求进行的冻结的；</p>
      <p class="main">
        （7）书营AI智能创作平台合理判断，您发生与如上行为性质相同或产生如上类似风险的其他情况的。
      </p>
      <p class="main">3.5. 申诉</p>
      <p class="main">
        <b
          >发生前述账户冻结情况下，您应及时予以关注并可以按照程序进行申诉等后续操作：</b
        >
      </p>
      <p class="main">
        （1）您通过申诉程序，向书营AI智能创作平台申请解除上述冻结的，为了您的账户安全，您应配合如实提供身份证明及相关资料，以及书营AI智能创作平台要求的其他信息或文件，以便书营AI智能创作平台进行核实。您应充分理解您的申诉并不必然被允许，书营AI智能创作平台有权决定是否同意您的申诉请求。
      </p>
      <p class="main">
        （2）<b
          >您理解并同意，如果您拒绝如实提供身份证明及相关资料，或未能通过书营AI智能创作平台审核，书营AI智能创作平台有权长期冻结该等账户且长期限制账户部分或全部功能。</b
        >
      </p>
      <p class="main_title">四、网站服务及规范</p>
      <p class="main">
        您有权享受书营AI智能创作平台提供的互联网技术服务和信息服务。您同时还应根据本服务条款以及您在购买具体服务时确认的相关条款和条件，履行及时付款、服务管理等责。您使用书营AI的服务，您应保证：
      </p>
      <p class="main">
        （1）
        <b
          >您使用书营AI服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用书营AI智能创作平台提供的服务进行存储、发布、传播如下信息和内容：</b
        >
      </p>
      <div class="main">
        <li>违反国家法律法规政策的任何内容（信息）；</li>
        <li>违反国家规定的政治宣传和/或新闻信息；</li>
        <li>涉及国家秘密和/或安全的信息；</li>
        <li>封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；</li>
        <li>博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；</li>
        <li>妨碍互联网运行安全的信息；</li>
        <li>侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；</li>
        <li>您同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等；</li>
        <li>不应出现任何破坏或试图破坏网络安全等的行为，包括不会利用技术或其他手段破坏或扰乱本网站及书营AI其他客户的网站；</li>
        <li>使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对平台及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</li>
    </div>
      <p class="main">（2）<b>利用或针对平台及相关服务进行任何危害计算机网络安全的行为，包括但不限于：</b></p>
      <div class="main">
        <li>非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</li>
        <li>提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</li>
        <li>明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、 支付结算等帮助；</li>
        <li>使用未经许可的数据或进入未经许可的服务器/账号；</li>
        <li>未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</li>
        <li>未经许可，企图探查、扫描、测试系统或网络的弱点或其它实施破坏网络安全的行为；</li>
        <li>企图干涉、破坏平台的正常运行，故意传播恶意程序或病毒以及其他坏干扰正常网络信息服务的行为；</li>
        <li>伪造TCP/IP数据包名称或部分名称；</li>
        <li>对平台及服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码；</li>
        <li>恶意注册平台的账号，包括但不限于频繁、批量注册账号；</li>
        <li>违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</li>
      </div>
      <p class="main">如您违反上述保证，书营AI智能创作平台除有权根据相关服务条款采取删除信息、中止服务、终止服务的措施，并有权冻结或注销您账户部分或全部功能。</p>
      <p class="main_title">五、隐私及个人信息的保护</p>
      <p class="main">您的信任对书营AI非常重要，书营AI智能创作平台深知用户信息安全的重要性，<b>书营AI将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。</b></p>
      <p class="main_title">六、知识产权</p>
      <p class="main">我们尊重知识产权，反对并打击侵犯知识产权的行为，用户创作的内容，仅代表其个人的立场，并不代表书营AI的立场。<b>书营AI智能创作平台仅为用户内容创作提供参考，作为内容的发表者，需自行对所发表的内容负责。因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。书营AI智能创作平台不对用户的观点和真实性负责，也不构成任何其他建议，不承担任何法律及连带责任。</b></p>
      <p class="main"><b>书营AI智能创作平台提供的参考内容均来自互联网AI工具公开信息，本公司不拥有著作权，仅供用户参考。用户利用本服务可能侵犯第三方著作权或其他知识产权的，用户应遵守相关法律法规，取得合法授权或许可；</b>若权利人对于用户使用本服务产生的任何内容享有合法权利的，应及时通知书营AI并提交合法权利声明，书营AI智能创作平台将依据相关法律法规采取移除、删除或屏蔽等处理措施。</p>
      <p class="main">用户在书营AI智能创作平台创作的内容，若引用其他人的内容，需尊重原作者或素材提供者的版权权力，<b>我们支持原创且不赞同任何抄袭行为。用户从第三方导入至本平台的内容依旧归原作者所有。</b>如果用户在创作的内容中引用的素材涉嫌侵权行为，请用户与原作者协商联系。</p>
      <p class="main_title">七、保密</p>
      <p class="main">书营AI智能创作平台承诺对您注册账户时或使用书营AI服务时提交或知悉的信息采取保密措施，不向第三方披露您的信息，除非：</p>
      <div class="main"></div>
        <li>依据本服务条款或者您与书营AI智能创作平台之间其他服务协议、合同、在线条款等规定可以提供的；</li>
        <li>依据法律法规的规定或行政、司法等职权部门要求应当提供的；</li>
        <li>在不违反本服务条款约定责任的前提下，该保密信息已经公开或能从公开领域获得。</li>
      <p class="main_title">八、付费相关条款</p>
      <p class="main">8.1.“书营AI智能创作平台”提供免费或付费产品供您使用，<b>书营AI有权依据市场需求调整或新推出付费产品、功能或服务及相关的服务费用。</b>具体调整均以书营AI官网公示为准。</p>
      <p class="main">在<b class="company">艺阁集团</b>降低收费服务的收费标准或者将收费服务改为免费服务提供时，<b class="company">艺阁集团</b>保留不对原付费用户提供退费或者费用调整之权利。</p>
      <p class="main">8.2.您有权自由选择决定是否使用“书营AI智能创作平台”中的付费产品、功能或服务。<b>书营AI智能创作平台会尽力但不保证对您的每一次付费操作进行确认提醒，包括但不限于付费金额和服务内容。</b></p>
      <p class="main">8.3.<b>书营AI智能创作平台可能要求您提供经书营AI认可的发卡机构发行的信用卡或借记卡和其他第三方支付平台，以收取您购买服务的对应费用。</b></p>
      <p class="main">8.4.服务费用不包含因您使用“书营AI智能创作平台”，访问互联网或移动网络而产生的相关费用，<b>您需要自行承担因使用“书营AI智能创作平台”服务而向包括网络运营商在内的第三方支付的相关费用，</b>例如，互联网接入费、手机流量费、手机短信费等。</p>
      <p class="main">8.5.书营AI智能创作平台会尽力但不保证提供完善的付费产品、功能或服务的相关内容，提供方式包括但不限于页面提醒和客服服务。您有权在选择购买前，通过以上渠道，详细了解所有产品功能和服务细则。<b>书营AI智能创作平台不承担因您未充分了解付费产品、功能或服务的内容，而提出的退款或赔偿诉求。本平台服务属于虚拟数据服务，不适用七天无理由退换货规定。</b></p>
      <p class="main">8.6.您在购买书营AI智能创作平台增值服务（包含但不限于账号升级、功能服务、内容素材、使用权限等），<b>因您主观原因（包含但不限于不愿继续持有、非书营AI产品或服务问题等）产生的退款行为，书营AI智能创作平台有权不进行受理。</b></p>
      <p class="main">8.7.您有权充分了解或评估使用书营AI智能创作平台产品存在的风险，包含但不限于网络问题，信息安全，版权问题以及其他非书营AI智能创作平台服务所导致的产品使用异常。</p>
      <p class="main">8.8.书营AI智能创作平台依据现有技术提供服务，并全力维护网站的正常运行，但书营AI智能创作平台无法随时预见任何技术上的问题或其他困难，该等困难可能导致您的使用受到影响（包含但不限于制作、推广等），<b>您需谨慎考虑使用书营AI智能创作平台所提供的服务可能带来的风险，如因此类问题产生的退款行为，书营AI智能创作平台不负任何直接或间接赔偿责任。</b></p>
      <p class="main">8.9.书营AI智能创作平台提供与其它网站或资源的链接，您可能会因此连结至其它运营商经营的网站，但不表示书营AI智能创作平台与这些运营商有任何关系，书营AI智能创作平台不对上述链接网站及其网页内容进行管理监督。<b>因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，书营AI智能创作平台有权协商处理，但无承担任何直接或间接赔偿责任的义务。</b></p>
      <p class="main">8.10.您有权充分了解书营AI智能创作平台给予的产品使用和功能权限，并根据自身需自愿选择付费使用您所需要的功能和服务。<b>如因您自身原因（包含但不限于未仔细阅读相关服务协议、审核规则、账号权限等）未能充分了解书营AI智能创作平台提供的服务，在购买服务后未能得到预期的效果（包含但不限于消耗类服务、数据类服务，推广类服务等）产生的退款行为，以及相关的损害或损失，书营AI智能创作平台不负任何直接或间接赔偿责任。</b></p>
      <p class="main">8.11.您在书营AI智能创作平台所产生的付款，可能被用作支付某些第三方版权与服务的费用，<b>因第三方使用过程中产生的纠纷，书营AI智能创作平台有权协商处理，但无承担任何直接或间接赔偿责任的义务。</b></p>
      <p class="main">8.12.在任何情况下，书营AI智能创作平台均无须对任何间接性、后果性、惩戒性、偶然性、特殊性或刑罚性的损害(包括但不限于用户因使用书营AI服务而遭受损失及对他人造成的损失)承担责任；</p>
      <p class="main">8.13.<b>书营AI智能创作平台对用户承担的全部责任总额，无论因何原因（基于合同法、侵权法或其他法规）或何种行为方式产生，始终不超过用户在服务期内因使用书营AI智能创作平台服务而已经支付给书营AI智能创作平台的费用。</b></p>
      <p class="main">8.14.书营AI智能创作平台会尽力对您在使用付费产品，功能和服务过程中遇到的问题，提供可行的解决方案，并最终解决您的问题。<b>如非书营AI智能创作平台产品，功能和服务问题所导致的使用异常，书营AI智能创作平台不负任何直接或间接赔偿责任。</b></p>
      <p class="main">您使用书营AI智能创作平台的收费服务时，应当按照书营AI智能创作平台的要求支付相应的费用。而且该等权利属于书营AI智能创作平台的经营自主权，书营AI智能创作平台保留随时更改经营模式的权利， 即保留变更收费的费率标准、收费的软件功能、收费对象及收费时间等权利。</p>
      <p class="main">8.15.如您是未成年人或限制民事行为能力人，请在监扩人同意后进行交易。<b>进行交易时请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。</b>如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</p>
      <p class="main">8.16您的交易行为应当基于真实的消费需求，不得存在对商品或服务实施恶意购买、恶意维权等扰乱书营AI智能创作平台正常交易秩序的行为。基于维护书营AI智能创作平台交易秩序及交易安全的需要，<b class="company">艺阁集团</b>发现上述情形时可主动执行关闭相关交易订单等操作。</p>
      <p class="main">如您的交易相对方存在利用书营AI智能创作平台平台系统漏洞、规则漏洞，不正当获取平台补贴等任何利益的行为，基于维护书营AI智能创作平台交易秩序、交易安全及<b class="company">艺阁集团</b>合法权益的需要，<b class="company">艺阁集团</b>发现上述情形时可主动执行关闭相关交易订单等操作。</p>
      <p class="main_title">九、责任范围和责任限制</p>
      <p class="main">9.1.您了解并同意，您应承担因您使用本服务、违反本服务条款或在您的账户下采取的任何行动而导致的任何第三方索赔。<b>如果由此引起艺阁集团及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并赔偿艺阁集团及其关联公司由此遭受的全部损失和责任。</b></p>
      <p class="main">9.2.在适用法律许可的范围内，艺阁集团不对与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失承担赔偿责任。</p>
      <p class="main">9.3.书营AI智能创作平台在此提示，您在使用书营AI智能创作平台服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用书营AI智能创作平台的服务从事侵犯他人名誉、隐私、知识产权和其他合法权益的活动。书营AI智能创作平台不对您使用书营AI智能创作平台服务的违法或违约行为承担任何责任。</p>
      <p class="main">9.4.用户在书营AI智能创作平台处理相关的内容数据信息，包括但不限于用户名称、公司名称、 联系人及联络信息，相关图片、资讯等，均由用户自行提供，书营AI智能创作平台的用户须对其提供的前述信息依法承担全部责任。</p>
      <p class="main_title">十、不可抗力和意外事件</p>
      <p class="main">书营AI智能创作平台可能因为下列不可抗力或意外事件无法正常运行的，书营AI智能创作平台不承担损害赔偿责任：</p>
      <div class="main">
        <li>因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</li>
        <li>因电力供应故障、通讯网络故障等公共服务因素；</li>
        <li>经提前公告或通知的，书营AI在短时间内的系统维护。</li>
      </div>
      <p class="main_title">十一、 法律适用与管辖</p>
      <p class="main">本服务条款之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本服务条款产生之争议应协商解决，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交书营AI所在地法院管辖。</p>
      <p class="main_title">十二、通知送达</p>
      <p class="main">12.1. 您理解并同意，书营AI智能创作平台可通过网页公告、电子邮件、站内信、短信、电话、系统消息以及即时通信等以上一种或多种通知方式向您发送通知，且书营AI智能创作平台可以信赖您所提供的联系信息是完整、准确且当前有效的；上述通知在发送成功后视为已送达。</p>
      <p class="main">12.2．除非本服务条款另有约定或书营AI智能创作平台与您另行签订的协议明确规定了通知方式，您发送给书营AI智能创作平台的通知，应当通过书营AI智能创作平台对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>
      <p class="main_title">十三、条款的更新和终止</p>
      <p class="main">13.1. 书营AI智能创作平台有权对本服务条款及相应的服务规则内容进行变更，并将网页公告、电子邮件、站内信、短信等方式予以公告或通知；若您在本服务条款内容变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的条款内容。</p>
      <p class="main">13.2. 在您的账户注销或经双方协商一致终止网站服务的，本服务条款终止。</p>
      <p class="main_title">十四、其他</p>
      <p class="main">14.1. 本服务条款由本服务条款的内容、网站相关页面上展现的规则、规范，服务说明（含操作文档）和您点击确认的其他条款/条件组成，书营AI智能创作平台和您均受其约束，且其中的相关名词可相互引用、解释。</p>
      <p class="main">14.2. 本服务条款的章节标题仅为行文方便而设，不具有法律或合同效力。</p>
      <p class="main">14.3. 本服务条款任一条款被视为废止、无效或不可执行，该条款应视为可分的且不影响本服务条款及其他条款的有效性及可执行性。</p>
      <p class="main">14.4. 艺阁集团有权在书营AI智能创作平台官网（www.showwin.net）上发布公告、发站内通知或邮件通知等方式将本服务的权利义务全部或者部分转移给艺阁集团的关联公司。</p>
      <p class="main">14.5. 除另有约定外，出于服务专业性考虑，书营AI智能创作平台亦可能委托关联公司或其他法律主体向您提供书营AI智能创作平台上一项或多项具体服务，同时<b>您可能会与该等公司订立相关条款或条件，请您审慎阅读、充分理解条款和条件内容，选择接受或不接受该条款和条件。</b></p>
      <p class="main">14.6. 本服务条款项下之保密条款、知识产权条款、法律适用与管辖条款以及性质上理应存续的其他条款（如对注册信息的真实性保证等），不因本条款的终止而失效。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.agrement_acontent {
  .company {
    font-weight: 500;
    color: red;
  }
  max-width: 49.375rem;
  margin: 0 auto;
  box-shadow: 0 0 11px 0 rgba(63, 95, 150, 0.04);
  box-sizing: border-box;
  padding: 1.25rem;
  .title {
    width: 100%;
    height: 7.5rem;
    box-sizing: border-box;
    padding: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    text-align: center;
    font-size: 2.125rem;
    font-weight: 900;
    color: #1f2329;
    border-bottom: 2px #1f2329 solid;
  }
}

.container_precondition {
  margin: 1.25rem 0 0 0;
  .main_title {
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    margin: 0 0 1.25rem 0;
  }
  .main {
    margin: 0 0 1.25rem 0;
    text-align: left;
    line-height: 1.25rem;
  }
}

.container_main {
  .company {
    font-weight: 500;
    color: red;
  }
  .main_title {
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    margin: 0 0 1.25rem 0;
  }
  // .main_title_h2 {
  //   text-align: left;
  //   font-weight: 600;
  //   margin: 0 0 1.25rem 0;
  // }
  .main {
    margin: 0 0 1.25rem 0;
    text-align: left;
    line-height: 1.25rem;
    li {
      list-style: square;
    }
    li:last-child {
      margin: 0 0 1.25rem;
    }
  }
}
</style>
